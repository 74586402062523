<template>
  <div class="container">
    <div class="header mb10">
      <div class="header-center"><van-icon name="gold-coin-o" size="20" /> {{ credit }} <span style="font-size:14px;">积分</span></div>
      <div class="header-right" @click="onNavigator({path:'/student/credits/index'})">积分明细<van-icon name="arrow" class="ml5" /></div>
    </div>
    <div class="main">
      <van-list v-model="listLoading" class="list" :finished="listFinished" :finished-text="listFinishedText" @load="onListLoad">
        <div v-for="item in list" :key="item.product_id" class="list-item">
          <div class="list-item-media">
            <van-image class="list-item-media-img" :src="item.image" />
          </div>
          <div class="list-item-inner">
            <div class="list-item-title">{{ item.title }}</div>
            <div class="list-item-info van-multi-ellipsis--l2">{{ item.desc }}</div>
            <div class="list-item-txt">{{ item.credit_count }}积分</div>
          </div>
          <div class="list-item-right">
            <van-button type="primary" size="small" :disabled="item.credit_count > credit" @click="onJumpDetail(item)">去兑换</van-button>
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>
<script>
import PageMixin from '@/mixins/page'
import storage from 'good-storage'

export default {
  name: 'Credits',
  mixins: [PageMixin],
  data() {
    return {
      credit: 0,
      list: [],
      listLoading: false,
      listFinished: false,
      listFinishedText: '没有更多了',
      pageSize: 10,
      pageCurrent: 1
    }
  },
  created() {
    // this.onPage()
  },
  methods: {
    onListLoad() {
      this.onPage()
    },
    onPage() {
      this.$api.credits_index({ 'per-page': this.pageSize, page: this.pageCurrent }).then(res => {
        this.listLoading = false
        this.list = Number(res._meta.currentPage) === 1 ? res.items : this.list.concat(res.items)
        this.listFinished = this.list.length >= res._meta.totalCount
        this.listFinishedText = this.list.length ? '没有更多了' : '暂无相关数据~'
        this.pageCurrent += 1

        this.credit = res._data.credit
      })
    },
    onJumpDetail(item) {
      const creditsProduct = {
        product_id: item.product_id,
        catimg: item.image,
        count: 1,
        product_name: item.title,
        specname: item.desc,
        sale_price: item.credit_count
      }
      storage.session.remove('choseAddress')
      storage.session.set('creditsExchangeList', [creditsProduct])
      this.onNavigator({ path: '/credits/exchange?product_id=' + item.product_id })
    }
  }
}
</script>
<style lang="less" scoped>

   .header {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 180px;
    color: #fff;
    padding: 0 15px;
    // background-color: #fe4140;
    background: @linear-gradient-color;
    // background-image: url('~@/assets/user_bg.png');
    &-center {
      font-size: 24px;
    }
    &-title {
      font-size: 18px;
    }
    &-right {
      position: absolute;
      top: 30px;
      right: 30px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      font-size: 14px;
    }
  }
  .list{
    padding: 0 15px;
  }

  .list-item{
    box-sizing: border-box;
    width: 100%;
    padding: 10px 0;
    padding-left: 10px;
    padding-right: 10px;
    background: #fff;
    margin-bottom: 10px;
    // border-radius: 8px;
    display: flex;
    &-media{
      margin-right: 10px;
      &-img{
        width: 80px;
        height: 80px;
      }
    }
    &-inner{
      flex: 1;
      margin-right: 10px;
    }
    &-title{
      font-size: 16px;
      color: #333;
      margin-bottom: 5px;
    }
    &-info{
      min-height: 40px;
      font-size: 14px;
      color: #666;
    }
    &-txt{
      font-size: 14px;
      color: #999;
    }
    &-right{
      display: flex;
      align-items: center;
    }
  }
</style>
